import {
  ChangeDetectorRef,
  Directive,
  HostListener,
  Optional,
} from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatListItem } from '@angular/material/list';
import { AuthService } from './auth.service';
import { NeedPermissionButtonDirective } from './need-permission-button.directive';

@Directive({
  selector: 'a[glxNeedPermissionAnchor], a[need-permission]',
})
export class NeedPermissionAnchorDirective extends NeedPermissionButtonDirective {
  @HostListener('click', ['$event'])
  haltDisabledEvenu(event: Event) {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  constructor(
    auth: AuthService,
    cd: ChangeDetectorRef,
    @Optional() anchor: MatAnchor | null,
    @Optional() listItem: MatListItem | null
  ) {
    super(auth, cd, anchor, listItem, null);
  }
}
