<div *ngIf="isLoading" class="loading-shade">
  <mat-spinner color="primary"></mat-spinner>
</div>

<section ngClass="large mat-elevation-z5">
  <header class="flex-row main-axis-center cross-axis-center gap-16">
    <mat-icon svgIcon="logo"></mat-icon>
    <h1>Galaxy</h1>
  </header>

  <div
    class="subtitle flex-row cross-axis-center main-axis-start gap-16"
  >
    <h2 class="flex-1">Authentication</h2>
    <mat-divider style="min-width: 96px;"></mat-divider>
  </div>

  <form [formGroup]="form" (ngSubmit)="signIn()">
    <mat-form-field>
      <mat-label>login</mat-label>
      <input matInput formControlName="login" placeholder="Put your login" />
      <mat-error *ngIf="form.hasError('required', 'login')"
        >Login required</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input
        matInput
        formControlName="password"
        placeholder="Put your password"
        type="password"
      />
      <mat-error *ngIf="form.hasError('required', 'password')"
        >Password required</mat-error
      >
    </mat-form-field>
    <mat-checkbox formControlName="rememberMe">Remember Me</mat-checkbox>
    <div class="sign-in-button-container">
      <button mat-flat-button color="primary" [disabled]="form.invalid">
        <mat-icon matPrefix>{{ form.invalid ? "lock" : "lock_open" }}</mat-icon>
        Sign In
      </button>
      <button type="button" (click)="sso()" class="sso" mat-raised-button>
        <div>Sign In with</div>
        <img src="https://sesame-sso.contactdve.com/microsoft.svg">
  
      </button>
    </div>
 
    <a
      class="forgotten-password-link"
      href="https://dv-access.com?origin=https://galaxy-auth.dv-content.com/"
      target="_blank"
    >
      Forgot your password ?
    </a>
  </form>
  <footer class="hide-gt-xs hide-gt-sm">
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </footer>
</section>
<footer class="hide-xs">
  <ng-container *ngTemplateOutlet="footer"></ng-container>
</footer>

<ng-template #footer>
  <p>by Digital Virgo</p>
  <glx-dark-mode-toggle></glx-dark-mode-toggle>
</ng-template>
