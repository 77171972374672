import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, finalize, iif, map, switchMap, take } from 'rxjs';
import { AuthService } from '../auth.service';
import { DOCUMENT } from '@angular/common';
import { LoadingSpinnerService } from 'src/app/shared/services/loading-spinner.service';

@Component({
  selector: 'glx-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  
})
export class SignInComponent implements OnInit {
  isLoading = false;

  form = this.fb.nonNullable.group({
    login: ['', Validators.required],
    password: ['', Validators.required],
    rememberMe: [true],
  });
  
  constructor(
      private loadingService: LoadingSpinnerService,
      private auth: AuthService,
      private route: ActivatedRoute,
      private router: Router,
      private fb: FormBuilder,
      @Inject(MatSnackBar) public snackBar: MatSnackBar,
      @Inject(DOCUMENT) private document: Document
    ) {
      
    }
  ngOnInit(): void {
    this.route.snapshot.queryParamMap.has('dv_token') && this.callback();
  }
  callback(){
    this.loadingService.show();
    this.auth.ssoCallback().pipe(
      finalize(() => this.loadingService.hide())
    ).subscribe({
      next: () => this.router.navigate(['/']),
      error: ({ error }) => {
        this.snackBar.open(`Authentication failed : ${error.message}`, 'OK', {
          panelClass: 'error-snack-bar',
          duration: 3000,
        });
      }
    })
  }
  sso(){
      this.auth.ssoInit().pipe(
      take(1),
      map(({redirectUrl}) => redirectUrl),
    )
    .subscribe({
      next: (redirect) => this.document.location.href = redirect,
      error: ({ error }) => {
        this.snackBar.open(`Authentication failed : ${error.message}`, 'OK', {
          panelClass: 'error-snack-bar',
          duration: 3000,
        });
        }
    });
  }
 
  signIn() {
    const { login, password, rememberMe } = this.form.getRawValue();
    this.isLoading = true;

    iif(
      () => rememberMe,
      this.auth.signInAndRemember(login, password),
      this.auth.signIn(login, password)
    )
      .pipe(
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: () => {
          const redirectUrl =
          this.route.snapshot.queryParamMap.get('redirectTo') || '/';
          this.router.navigate([redirectUrl]);
        },
        error: ({ error }) => {
          this.snackBar.open(`Authentication failed : ${error.message}`, 'OK', {
            panelClass: 'error-snack-bar',
            duration: 3000,
          });
        },
      });
  }
}
