import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  CanDisable,
  HasTabIndex,
  mixinDisabled,
  mixinTabIndex,
} from '@angular/material/core';
import { MatListItem } from '@angular/material/list';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { BehaviorSubject, of, startWith, Subscription, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

const NeedPermissionBase = mixinTabIndex(mixinDisabled(class {}));

@Directive({
  selector:
    'button[glxNeedPermissionButton], button[need-permission], mat-slide-toggle[need-permission]',
})
export class NeedPermissionButtonDirective
  extends NeedPermissionBase
  implements CanDisable, HasTabIndex, OnInit, OnDestroy
{
  @Input()
  override get disabled(): boolean {
    return !this._hasPermission || this.disabledSubject.value;
  }
  override set disabled(disabled: BooleanInput) {
    this.disabledSubject.next(coerceBooleanProperty(disabled));
  }
  private disabledSubject = new BehaviorSubject(false);

  @HostBinding('attr.tabindex')
  @Input()
  override get tabIndex(): number {
    return this.disabled ? -1 : this._tabIndex || 0;
  }
  override set tabIndex(tabIndex: number) {
    this._tabIndex = tabIndex;
  }
  private _tabIndex: number | undefined;

  @HostBinding('attr.disabled')
  get attrDisabled(): true | null {
    return this.disabled || null;
  }
  @HostBinding('attr.aria-disabled')
  get attrAriaDisabled(): string {
    return this.disabled.toString();
  }
  @HostBinding('class')
  get className(): string {
    return this.disabled ? 'need-permission-deny' : 'need-permission-allow';
  }

  @Input('need-permission')
  get needPermission(): string | string[] | undefined {
    return this.atmNeedPermission;
  }
  set needPermission(permission: string | string[] | undefined) {
    this.atmNeedPermission = permission;
  }

  @Input()
  get atmNeedPermission(): string | string[] | undefined {
    return this.neededPermissionSubject.value;
  }
  set atmNeedPermission(permission: string | string[] | undefined) {
    this.neededPermissionSubject.next(permission);
  }
  private neededPermissionSubject = new BehaviorSubject<
    string | string[] | undefined
  >(undefined);
  private permissionSub = Subscription.EMPTY;

  private _hasPermission = false;

  constructor(
    private auth: AuthService,
    private cd: ChangeDetectorRef,
    @Optional() private button: MatButton | null,
    @Optional() @Self() private listItem: MatListItem | null,
    @Optional() private slideToggle: MatSlideToggle | null
  ) {
    super();
  }

  ngOnInit(): void {
    this.permissionSub = this.neededPermissionSubject
      .pipe(
        switchMap((neededPermission) => {
          if (!neededPermission) {
            return of(true);
          }
          const neededPermissions =
            typeof neededPermission === 'string'
              ? neededPermission.split(',')
              : neededPermission;
          return this.auth.userHasSomePermissions(neededPermissions);
        }),
        startWith(false)
      )
      .subscribe((hasPermission) => {
        this._hasPermission = hasPermission;
        if (this.button) {
          this.button.disabled = this.disabled;
        }
        if (this.listItem) {
          this.listItem.disabled = this.disabled;
        }
        if (this.slideToggle) {
          this.slideToggle.disabled = this.disabled;
        }
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.permissionSub.unsubscribe();
  }
}
